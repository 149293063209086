import React from 'react'
import mobile from '../Mobile.svg';

export const Mobile = () => {
    return (
        <div className="center mobile-section">
            <img className="mobile-img" src={mobile} alt="Mobile view is not available" />
            <h2  className="mobile-text">Mobile view is not available</h2>
        </div>
    )
}
