import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="container footer-copyright">
                Designed and Created with <i className="uil uil-heart heart-icon"></i> by <a href="https://brainifii.com/" target="blank_" className="footer-link">BRAINIFII</a> | All rights not reserved. 😉
            </div>
        </footer>
    )
}
