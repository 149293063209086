// import logo from './logo.svg';
import './App.css';
import Footer from './MyComponents/Footer';
import { Todos } from './MyComponents/Todos';
import { Mobile } from './MyComponents/Mobile';
import { AddTodo } from './MyComponents/AddTodo';
// import { DYK } from './MyComponents/DYK';
import { VisitorCount } from './MyComponents/VisitorCount';
import React, { useState, useEffect } from 'react';

import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  let initTodo;
  if (localStorage.getItem("todos") === null) {
    initTodo = [];
  } else {
    initTodo = JSON.parse(localStorage.getItem("todos"));
  }
  const onDelete = (todo) => {
    setTodos(todos.filter((e) => {
      return e !== todo;
    }));
  }

  const onClickTitle = (todo) => {
    var clip = todo.Values[0]['Title'];
    clip = clip.trim();
    navigator.clipboard.writeText(clip);
    toast.success("Title Copied");
  }

  const onClickDoi = (todo) => {
    var clip = todo.Values[0]['DOI'];
    clip = clip.trim();
    navigator.clipboard.writeText(clip);
    toast.success("Doi Copied");
  }

  const onClickCitation = (todo) => {
    var clip = todo.Values[0]['Citation'];
    clip = clip.trim();
    navigator.clipboard.writeText(clip);
    toast.success("Citation Copied");
  }

  const addTodo = (title) => {
    let final_link = null
    if (title.includes('https') === true) {
      final_link = "https://symplhub.herokuapp.com/params?link=" + title.trim()
    } else {
      final_link = "https://symplhub.herokuapp.com/params?link=https://" + title.trim()
    }
    if (title.includes('ieeexplore.ieee.org') === true) {
      if (title === "ieeexplore.ieee.org") {
        toast.error("You entered the actual domain, Please verify and try again.")
      } else {
        final_link = "https://symplhub.herokuapp.com/params?link=" + title.trim()
        // const toastId = React.useRef(null);
        // toast.success("Query executed successfully.");
        const id = toast.loading("Fetching, Please wait!!");

        axios.get(final_link)
          .then((response) => setTodos([...todos, response.data]))
          .then((response) => toast.update(id, { render: "Added Successfully!!", type: "success", isLoading: false, autoClose: 5000 }))
          .catch(error => {
            if (error.response.status !== 200) {
              toast.update(id, { render: "Paper not found", type: "error", isLoading: false, autoClose: 5000 })
              toast.info("Maybe the link is incorrect.\nRight now only IEEE and Springer papers can be fetched.")
            }
          });
      }
    } else if (title.includes('link.springer.com') === true) {
      if (title === "link.springer.com") {
        toast.error("You entered the actual domain, Please verify and try again.")
      } else {
        final_link = "https://symplhub.herokuapp.com/params?link=" + title.trim()
        const id = toast.loading("Fetching, Please wait!!");

        axios.get(final_link)
          .then((response) => setTodos([...todos, response.data]))
          .then((response) => toast.update(id, { render: "Added Successfully!!", type: "success", isLoading: false, autoClose: 5000 }))
          .catch(error => {
            if (error.response.status !== 200) {
              toast.update(id, { render: "Paper not found", type: "error", isLoading: false, autoClose: 5000 })
              toast.info("Maybe the link is incorrect.\nRight now only IEEE and Springer papers can be fetched.")
            }
          });
      }
    } else {
      toast.error("Right now only ieeexplore.ieee.org and link.springer.com domains are accepted.")
    }
    localStorage.setItem("todos", JSON.stringify(todos));
  }

  const [todos, setTodos] = useState(initTodo);
  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos])

  return (
    <>
      {
        loading ?

          <div className="loading">
            <ClimbingBoxLoader
              color={"#2962ff"}
              loading={loading}
              size={30}
            />
          </div>

          :
          <>
            <div className="main-block">
              <Router>
                {/* <Header /> */}
                <Switch>
                  <Route exact path="/" render={() => {
                    return (
                      <>
                        <AddTodo addTodo={addTodo} />
                        {/* <DYK/> */}
                        <Todos todos={todos} onDelete={onDelete} onClickTitle={onClickTitle} onClickDoi={onClickDoi} onClickCitation={onClickCitation} />
                      </>
                    )
                  }}>
                  </Route>
                </Switch>
                <VisitorCount />
                <Footer />
                <ToastContainer
                  position="top-right"
                  autoClose={7000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </Router>
            </div>

            <Mobile />
          </>
      }
    </>
  );
}

export default App;
