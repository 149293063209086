import React, { useState } from 'react'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AddTodo = (props) => {
    const [title, setTitle] = useState("");

    const submit = (e) => {
        e.preventDefault();
        if (!title) {
            toast.error("Title cannot be blank.", {
                autoClose: 5000,
            });
        }
        else {
            props.addTodo(title);
            setTitle("");
        }
    }

    return (
        <div className="add-link-bg">
            <h1 className="Main-title">Sympl<span className="Main-title-alt">Hub</span></h1>
            <div className="container submit-section">
                <form onSubmit={submit}>
                    <div className="add-main">
                        <div className="entry-box">
                            <div className="book-icon"><i className="uil uil-notebooks"></i></div>
                            <input type="text" value={title} onChange={(e) => { setTitle(e.target.value) }} className="input_add form-control" placeholder="Add a Springer/IEEE journal link" id="InputText" aria-describedby="InputText" />
                            <button type="submit" className="add-button" on><i className="uil uil-plus"></i></button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}
