import axios from 'axios'
import React, { useState, useEffect } from 'react';

export const VisitorCount = () => {
    const [allVisitors, setallVisitors] = useState([]);
    // https://api.countapi.xyz/create?namespace=symplhub&key=visitor&value=0
    // https://api.countapi.xyz/get/symplhub/visitor
    // https://api.countapi.xyz/set/symplhub?visitor?value=0
    // https://api.countapi.xyz/update/symplhub/visitor/?amount=1
    const getVisitor = () => {
        if(localStorage.getItem("UniqueVisitor") === null){
            localStorage.setItem("UniqueVisitor","False")
            axios.get('https://api.countapi.xyz/update/symplhub/visitor/?amount=1')
            .then((response) => {
                const visitors = response.data;
                setallVisitors(visitors);
            });
        } else {
            axios.get('https://api.countapi.xyz/get/symplhub/visitor')
            .then((response) => {
                const visitors = response.data;
                setallVisitors(visitors);
            });
        }
    };

    useEffect(() => getVisitor(), []);

    return (
        <div className="visitor-count">
            <h1 className="visitor-count-text">Unique visitors so far</h1>
            <h1 className="visitor-count-value">{allVisitors.value}</h1>
        </div>
    )
}
