import React from 'react'

export const TodoItem = ({ todo,onDelete,onClickTitle,onClickDoi,onClickCitation }) => {
    return (
            <tr>
                <td></td>
                <td ><span className="Table-Data-Title" onClick={()=>onClickTitle(todo)}>{todo.Values[0]['Title']}</span></td>
                <td ><span className="Table-Data-Doi" onClick={()=>onClickDoi(todo)}>{todo.Values[0]['DOI']}</span></td>

                <td>
                    <div className="table-action">
                        <a href={todo.Values[0]['Downloadable_Link']} target="_blank" rel="noreferrer" type="button" className="sympl-button-action table-action-download" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download"><i className="uil uil-download-alt"></i></a>
                        <button type="button" className="sympl-button-action table-action-copy" onClick={()=>onClickCitation(todo)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Copy Citation" ><i className="uil uil-copy-alt"></i></button>
                        <button type="button" className="sympl-button-action table-action-delete" onClick={()=>onDelete(todo)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"><i className="uil uil-trash-alt"></i></button>
                    </div>
                </td>
            </tr>
    )
}

