import nodata from '../NoData.webp';
import React from 'react';
import { TodoItem } from './TodoItem'
import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

export const Todos = (props) => {

    const downloadCSV = () => {
        try {
            const JSONdata = JSON.parse(localStorage.getItem('todos'))
            var csvRow = [];
            var A = [['id', 'title', 'citation', 'doi', 'journal_link', 'Downloadable_Link']];
            for (var item = 0; item < JSONdata.length; item++) {
                A.push([item + 1, JSONdata[item].Values[0].Title.trim(), JSONdata[item].Values[0].Citation.trim(), JSONdata[item].Values[0].DOI.trim(), JSONdata[item].Values[0].Value.trim(), JSONdata[item].Values[0].Downloadable_Link.trim()]);
            }
            for (var i = 0; i < A.length; ++i) {
                csvRow.push('"' + A[i].join('","'))
            }
            var csvString = csvRow.join('"%0A');
            var a = document.createElement("a");
            a.href = 'data:attachment/csv,' + csvString + '"';
            a.target = "_Blank";
            a.download = "SymplHub-lists.csv";
            document.body.appendChild(a);
            a.click();

            toast.success("Downloaded CSV successfully");
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong please report if you think something is wrong");
        }
    }

    return (
        <div className="container all-list-section">
            {props.todos.length === 0 ?
                <>
                    <h3 className="box-title">Your List</h3>
                    <div className="center">
                        <h2><i class="uil uil-times-circle no-data-text"></i> No Data found</h2>
                        <img className="no-data" src={nodata} alt="No Data Found" />
                    </div>
                </>
                :
                <>

                    <div className="menu-wrapper">
                        <div className="list-title">
                            <h3 className="box-title">Your List</h3>
                        </div>
                        <div className="more-option">
                            {/* <CSVLink {...csvdata}> */}
                            <button type="button" className="sympl-button download-all-button" onClick={() => downloadCSV()}><i className="uil uil-download-alt"></i> Download CSV</button>
                            {/* </CSVLink> */}
                        </div>
                    </div>
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Title</th>
                                <th>DOI</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.todos.map((todo) => {
                                return <TodoItem todo={todo} key={todo.sno} onDelete={props.onDelete} onClickTitle={props.onClickTitle} onClickDoi={props.onClickDoi} onClickCitation={props.onClickCitation} />
                            })}
                        </tbody>
                    </table>
                </>
            }
        </div >
    )
}
